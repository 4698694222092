<template>
  <div class="licences-table w-full">
    <vx-card title="Enthaltene Produkte/Leistungen">
      <vue-bootstrap4-table :rows="data" :columns="getColumns" :config="config" :actions="actions"
                            @on-change-per-page="onChangePerPage"
                            @on-change-query="onChangeQuery" :total-rows="total_rows"
                            @on-click-add="onCreateButtonClick"
                            @saved="fetchData">
                            columnSelection>
        <template slot="contractActive" slot-scope="props" class="column-active">
          <div class="vbt-table-td">
            <div class="pl-2">
              <vs-row>
                  <vx-tooltip text="Inaktiv">
                    <span class="mega--circle red" v-if="!props.row.contractActive"></span>
                  </vx-tooltip>
                  <vx-tooltip text="Aktiv">
                    <span class="mega--circle green" v-if="props.row.contractActive"></span>
                  </vx-tooltip>
              </vs-row>
            </div>
          </div>
        </template>

        <template slot="rent_or_buy" slot-scope="props">

          <span class="badge bg-success" v-if="props.row.rentOrBuy == 'rent'">Miete</span>
          <span class="badge bg-primary" v-if="props.row.rentOrBuy == 'buy'">Kauf</span>
        </template>


        <template slot="row_actions" slot-scope="props">
          <div class="column-actions-group">
            <vx-tooltip text="Bearbeiten" position="left" v-if="props.row.contractId != null && props.row.type == 'contract' " >
              <router-link :to="'/contracts/'+props.row.contractId" target= '_blank' >
                <vs-button size="small" class="mb-2" icon="edit">Vertrag öffnen</vs-button>
              </router-link>
            </vx-tooltip>

            <span class="badge badge-success" v-if="props.row.type == 'included-in-plan' ">Inkl. im Lizenzplan</span>

            <vx-tooltip text="Löschen" position="left" v-if="props.row.licenceProductId">
              <vs-button color="primary"
                         type="filled"
                         icon="delete_forever"
                         size="small"

                         @click="openDeleteConfirm(props.row.licenceProductId)"></vs-button>
            </vx-tooltip>
          </div>
        </template>
        <template slot="empty-results">
          Keine Lizenzen gefunden.
        </template>
      </vue-bootstrap4-table>
    </vx-card>

    <create-licenced-product-modal :active.sync="createModalActive" :licence="licence" @close="closeCreateModal()" @saved="closeCreateModal(true)"></create-licenced-product-modal>

  </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from "../../../api";
import moment from 'moment';
import QueryHelper from "../../../mixins/helper/query.helper";
import findIndex from "lodash/findIndex";
import vSelect from 'vue-select'
import CreateLicencedProductModal from "@/components/licence-manager/create-licenced-product-modal.vue";
import EventPopup from "@/views/dashboard/Calendar/components/EventPopup.vue";
var qs = require('qs');

export default {
  components: {
    EventPopup,
    CreateLicencedProductModal,
    VueBootstrap4Table,
    'v-select': vSelect
  },
  props: {
    licence: {
      type: Object,
    },
  },
  data() {
    return {
      data: [],
      createModalActive: false,
      actions: [
        {
          btn_text: "Hinzufügen",
          event_name: "on-click-add",
          color: 'success'
        }
      ],
      columns: [
        {
          label: "Gültiger Vertrag",
          slot_name: "contractActive",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'active-column',
          showCol: true,
          width:'2%'
        }, {
          label: "Produktname",
          name: "productName",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'id-column',
          showCol: true,
          width:'30%'
        },
        {
          label: "Technischer Name",
          name: "licenceTechnicalName",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'id-column',
          showCol: true,
          width:'15%'
        },
        {
          label: "Vertragsstart",
          name: "contractStart",
          slot_name: "start_date",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'date-column',
          showCol: true,
          formatter: this.formatDate
        },

        {
          label: "Miete/Kauf",
          name: "rentOrBuy",
          slot_name: "rent_or_buy",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'badge-column',
          showCol: true,
        },
        {
          label: "Vertragsende",
          name: "contractEnd",
          slot_name: "end_date",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'date-column',
          showCol: true,
          formatter: this.formatDate
        },



        {
          label: "Erstellt",
          name: "createdAt",
          slot_name: "created_at",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'date-column',
          showCol: false,
          formatter: this.formatDateTime
        },

        {
          label: "Aktionen",
          slot_name: "row_actions",
          sort: false,
          row_text_alignment: 'text-right',
          column_text_alignment: 'text-right',
          column_classes: 'actions-column',
          showCol: true,
        }],
      config: {
        checkbox_rows: false,
        show_refresh_button: false,
        show_reset_button: false,
        highlight_row_hover_color: '#f8f8f8',
        server_mode: true,
        per_page: 10
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
      total_rows: 0,
      selectedForDialog: null,
    }
  },
  computed: {
    requestParams() {
      return QueryHelper.parseRequestParams(this.queryParams);
    },
    getColumns() {
      return this.columns;
    },
  },
  methods: {
    fetchData() {
      this.$vs.loading();

      let licencesPromise = ApiService.get('b2b-sellers/licence/'+this.licence.id+'/products', {
        params: this.requestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      });

      licencesPromise.then((response) => {
        this.data = response.data.result;
        this.total_rows = response.data.total;
        this.$vs.loading.close();
      });
    },
    refreshLicences() {
      ApiService.get('b2b-sellers/licence', {
        params: this.requestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then((response) => {
        this.data = response.data.result;
        this.total_rows = response.data.total;
      });
    },
    closeCreateModal(saved = false) {
      this.createModalActive = false;
      if (saved) {
        this.fetchData()
      }

    },
    onCreateButtonClick() {
      this.createModalActive = true
    },
    formatDateTime(value) {
      return moment(new Date(value)).format('DD.MM.YYYY - H:m')
    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchData();
    },
    onChangePerPage(value) {
      this.config.per_page = value;
    },
    toggleActive(id, current) {
      const payload = {
        is_active: !current
      };

      ApiService.put('b2b-sellers/licence/' + id, payload).then(response => {
        this.fetchData();

        if(response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Der Status wurde erfolgreich geändert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Status konnte nicht geändert werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Status konnte nicht geändert werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    openDeleteConfirm(id) {
      this.selectedForDialog = id;

      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Bestätigen',
        text: 'Sind Sie sich sicher dass diese Lizenz gelöscht werden soll?',
        accept: this.acceptDelete,
        acceptText: 'Löschen',
        cancelText: 'Abbrechen'
      })
    },
    acceptDelete() {
      ApiService.delete('b2b-sellers/licence/product/' + this.selectedForDialog).then((response) => {
        console.log("response", response);
        this.fetchData();

        if(response.data.status === 'success') {
          this.lo
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Lizenz wurde erfolgreich gelöscht',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Lizenz konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Lizenz konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
  },
  mounted() {
    this.fetchData();
  }
}
</script>

<style lang="scss">
.licences-table {
  .id-column {
    width: 10%;
  }
  .domain-column {
    width: 20%;
  }
  .license-plan-column {
    width: 15%;
  }
  .date-column {
    width: 15%;
  }
  .actions-column {
    width: 15%;
  }
  .active-column {
    width: 10%;
  }
}

span.badge {
  background: #999999;
  color: #000;
  padding: 10px 15px;
  border-radius: 29px;
  font-weight: 600;
  font-size: 12px;
  margin-left: 10px;
  text-align: center;
  border: 1px solid #797979;

  &.badge-success {
    background: #61af33;
    color: #fff;
    border: 1px solid #457e22;
  }

}
</style>
