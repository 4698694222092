<template>
  <div class="licence-details" v-if="fetched && data">

    <div class="headerButtons" v-if="!isCreateForm">

    </div>

    <div class="vx-row mb-base">
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card title="Lizenz Details" class="h-full">

            <div class="vx-row">
            <div class="vx-col lg:w-full w-full">

              <div class="vx-row mt-0 mb-0">
                <div class="vx-col lg:w-1/2">
                  <vs-input class="w-full"
                            label="LICENCE-ID"
                            ref="licenceId"
                            v-model="data.id"
                            data-vv-as="licenceId"
                            name="licenceId"
                            type="text" :disabled="!isCreateForm" v-validate.initial="'uuidv4'"/>
                  <span class="text-danger text-sm" v-if="errors.has('licenceId')">{{ errors.first('licenceId') }}</span>
                </div>
                <div class="vx-col lg:w-1/2 mt-6 pt-2">
                  <span class="link" @click.stop.prevent="copyLicenceKey">Kopieren </span>
                </div>
              </div>
              <div v-if="isCreateForm">
                <i>Leer lassen, wenn es automatisch generiert werden soll.</i>
              </div>

              <div class="vx-row mt-5">
                <div class="vx-col lg:w-full">
                  <vs-checkbox v-model="data.is_active" class="ml-0">Aktiv</vs-checkbox>
                </div>
                <div class="vx-col lg:w-full mt-4">
                  <vs-checkbox v-model="data.isLive" class="ml-0">Shop ist live</vs-checkbox>
                </div>
              </div>

              <div class="vx-row mt-4">
                <div class="vx-col w-full sm:w-1/2">
                  <label>Lizenz-Eigentümer (Rechnungsempfänger):</label>
                  <mg-select v-model="data.owner_client_id" :options="clients" name="owner_client"
                             placeholder="Eigentümer Kunde auswählen"
                             track-by="id" label="company_name" data-vv-as="Eigentümer Kunde" v-validate="'required'">
                  </mg-select>
                  <span class="text-danger text-sm" v-if="errors.has('owner_client')">{{
                      errors.first('owner_client')
                    }}</span>
                </div>

                <div class="vx-col w-full mt-4 sm:mt-0 sm:w-1/2">
                  <label>Lizenz wird genutzt von (Nutzungsrecht):</label>
                  <mg-select v-model="data.usedby_client_id" :options="clients" name="usedby_client"
                             placeholder="Verwendet von Kunde auswählen"
                             track-by="id" label="company_name" data-vv-as="Verwendet von Kunde"
                             v-validate="'required'">
                  </mg-select>
                  <span class="text-danger text-sm" v-if="errors.has('usedby_client')">{{
                      errors.first('usedby_client')
                    }}</span>
                </div>
              </div>

              <div class="vx-row mt-4">
                <div class="vx-col w-full">
                  <label>Partner (Agentur)</label>
                  <mg-select v-model="data.partner_client_id" :options="clients" name="partner_client"
                             placeholder="Partner Kunde auswählen"
                             track-by="id" label="company_name" data-vv-as="Partner Kunde">
                  </mg-select>

                </div>

              </div>


              <div class="vx-row mt-4">
                <div class="vx-col w-full sm:w-1/2">
                  <vs-input class="w-full" label="Domain* (bspw. domain.tld)"
                            data-vv-as="Domain"
                            name="domain"
                            v-model="data.domain" type="text" v-validate.initial="'requiredDomain'"/>
                  <span class="text-danger text-sm" v-if="errors.has('domain')">{{ errors.first('domain') }}</span>
                </div>

                <div class="vx-col w-full mt-4 sm:mt-0 sm:w-1/2">
                  <label>Lizenzplan:</label>
                  <v-select
                      v-model="data.license_plan"
                      :options="licensePlans"
                      label="license_plan"
                      class="w-full"
                      placeholder="Lizenzplan auswählen">
                  </v-select>
                  <span class="text-danger text-sm" v-if="errors.has('license_plan')">{{
                      errors.first('license_plan')
                    }}</span>
                </div>
              </div>

              <div class="vx-row mt-4">
                <div class="vx-col lg:w-full">
                  <label class="vs-input--label">Test Domains (Semikolongetrennt bspw. test.de;test2.de)</label>
                  <vs-textarea class="w-full"
                               v-model="data.test_domains"></vs-textarea>
                </div>
              </div>

              <div class="vx-row mt-4">
                <div class="vx-col lg:w-full">
                  <label class="vs-input--label">Interner Kommentar</label>
                  <vs-textarea class="w-full"
                               v-model="data.internal_comment"></vs-textarea>
                </div>
              </div>


              <br><br>
              <div v-if="!isCreateForm">
                Erstellt am: {{ data.created_at | dateTime }} <br>
                Geändert am: {{ data.updated_at | dateTime }} <br>
              </div>
            </div>
          </div>

        </vx-card>
      </div>

      <!-- Marketing Approval Card -->
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card title="Marketingfreigabe" >
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-checkbox v-model="data.marketing_approval" @change="handleMarketingApprovalChange">Marketingfreigabe</vs-checkbox>
            </div>
            <div class="vx-col w-full mt-4">
              <label>Marketing Status</label>
              <v-select
                  v-model="data.marketing_status"
                  :options="['Wait for going live','Open', 'In Progress', 'Completed', 'on Hold', 'Cancelled']"
                  :disabled="!data.marketing_approval"
                  placeholder="Status auswählen"
                  class="w-full"
              ></v-select>

              <span v-if="showAlreadyLiveWarning" class="text-danger text-sm">
                Der Marketing-Status 'Wait for going live' ist nicht möglich, wenn der Shop bereits live ist.
              </span>
            </div>
            <div class="vx-col w-full mt-4">
              <label>Marketing Notiz</label>
              <vs-textarea v-model="data.marketing_notice" placeholder="Zusätzliche Notizen hinzufügen"  height="160px"></vs-textarea>
            </div>
          </div>
        </vx-card>

        <vx-card title="Provisionsvereinbarung" class="mt-3">
          <div class="vx-row">
            <div class="vx-col w-full">
              <label>Provisionsvereinbarung</label>
              <v-select
                  v-model="data.commission_agreement_type"
                  :options="commissionOptions"
                  label="label"
                  track-by="value"
                  placeholder="Vereinbarung auswählen"
                  class="w-full"
              ></v-select>
            </div>

            <div class="vx-col w-full sm:w-1/2 mt-4">
              <label>Provisionsempfänger</label>
              <mg-select
                  v-model="data.commission_recipient_client_id"
                  :options="clients"
                  name="commission_recipient_client"
                  placeholder="Empfänger auswählen"
                  track-by="id"
                  label="company_name"
              ></mg-select>
              <span v-if="recipientMismatch" class="text-danger text-sm">
                Achtung: Die Agentur, die die Provision erhält, ist nicht die gleiche, die das Projekt umsetzt.
              </span>
            </div>

            <div class="vx-col w-full sm:w-1/2 mt-4">
              <label>Provisionsprozentsatz</label>
              <vs-input
                  class="w-full"
                  v-model="data.commission_percentage"
                  type="number"
                  min="0"
                  max="100"
                  step="0.01"
                  placeholder="Prozentsatz eingeben"
              />
              <span v-if="percentageMismatch" class="text-danger text-sm">
                Der eingegebene Prozentsatz weicht von der hinterlegten Provision ({{ storedCommissionPercentage }}%) ab.
              </span>
            </div>



            <div class="vx-col w-full mt-4">
              <label>Provisionshinweis</label>
              <vs-textarea
                  class="w-full"
                  v-model="data.partner_provision_note"
                  placeholder="Zusätzliche Anmerkungen hinzufügen"
                  height="70px"
              ></vs-textarea>
            </div>
          </div>
        </vx-card>

        <history :histories="history" class="mt-4" v-if="!isCreateForm"></history>
      </div>
    </div>
    <div class="vx-row mb-base">
      <licence-detail-products :licence="data" v-if="$route.params.id && data"></licence-detail-products>
    </div>

    <div class="flex mt-8 justify-end">
      <vs-button color="warning" type="border" class="mb-4" @click="onClickDelete">Lizenz löschen</vs-button>
      <vs-button color="warning" type="border" class="ml-3 mb-4" :to="{name :'b2b-sellers-licence-list'}">Abbrechen
      </vs-button>
      <vs-button class="ml-3 mb-4" :disabled="!validateForm" @click="onSave" v-if="$route.params.id">Speichern
      </vs-button>
      <vs-button class="ml-3 mb-4" :disabled="!validateForm" @click="onCreate" v-else>Lizenz erstellen</vs-button>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../api";
import staticOptions from "../../../mixins/static/options";
import MgSelect from "../../../components/mg-select/MgSelect";
import vSelect from 'vue-select';
import moment from "moment";
import History from "@/components/history/History.vue";
import * as qs from "qs";
import LicenceDetailProducts from "@/views/b2bsellers-specific/licence-manager/Licence-detail-products.vue";

export default {
  components: {LicenceDetailProducts, History, MgSelect, vSelect},
  data() {
    return {
      data: null,
      fetched: false,
      licensePlans: ["Starter", "Advanced", "Unlimited", "old"],
      clients: [],
      history: [],
      message: 'Copy These Text',
      commissionOptions: [
        { value: "none_is_endcustomer", label: "Kein Partner – Direktkunde" },
        { value: "none_no_partner_agency", label: "Kein Partner – Keine Agentur" },
        { value: "none_accounting_via_agency", label: "Keine Provision – Abrechnung über Agentur" },
        { value: "none_individual_agreement", label: "Individuelle Provisionsvereinbarung" },
        { value: "partner_contract", label: "Provision nach Partnervertrag" }
      ],
      percentageMismatch: false,
      recipientMismatch: false,
      storedCommissionPercentage: null
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    } else {
      this.initializeData();
    }
    this.fetchClients();
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.data.domain !== '' && this.data.license_plan !== '';
    },
    isCreateForm() {
      if (this.$route.params.id) {
       return false
      }
      return true;
    },
    showAlreadyLiveWarning() {
      return (
          this.data.isLive &&
          this.data.marketing_approval &&
          this.data.marketing_status === "Wait for going live"
      );
    },
    historyRequestParams() {
      return {
        order: {
          'field': 'created_at',
          'direction': 'DESC'
        },
        filter: [{
          field: 'object_id',
          value: this.$route.params.id,
          expression: 'exact',
        },
          {
            field: 'object_type',
            value: "App\\\\Models\\\\B2BsellersSpecific\\\\B2BsellersLicence"
          }]

      }
    }
  },
  methods: {
    initializeData() {
      this.fetched = true;
      let now = moment().format('YYYY-MM-DD HH:mm:ss');
      this.data = {
        "is_active": 1,
        "domain": "",
        "test_domains": null,
        "license_plan": "",
        "internal_comment": null,
        "owner_client_id": null,
        "usedby_client_id": null,
        "partner_client_id": null,
        "created_at": now,
        "partner_provision_note" : null,
        "updated_at": now,
        "activity_log": [],
        "commission_percentage": 0,
        "commission_recipient_client_id": null,
        "commission_agreement_type": "none_is_endcustomer",
      }
    },
    fetchData() {
      ApiService.get('b2b-sellers/licence/' + this.$route.params.id)
          .then((response) => {
            if (response.data.status === 'success') {
              this.fetched = true;
              this.data = response.data.result;
              this.fetchHistory();
              if (!this.validateForm) {
                this.$vs.notify({
                  title: 'Formular Validation Fehler - bitte felder prüfen',
                  text: '',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
              }
            } else {
              this.$router.push({name: 'b2b-sellers-licence-list'});
            }
          })
          .catch((error) => {
            this.$router.push({name: 'b2b-sellers-licence-list'});
          })
    },
    fetchClients() {
      ApiService.get('clients')
          .then((response) => {
            if (response.data.status === 'success') {
              this.clients = response.data.result;
            } else {
              this.$vs.notify({
                title: 'Clients konnten nicht geladen werden',
                text: '',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            }
          })
          .catch((error) => {
            this.$vs.notify({
              title: 'Clients konnten nicht geladen werden',
              text: '',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
    },
    fetchHistory() {
      ApiService.get(`activities`, {
        params: this.historyRequestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then((response) => {
        if (response.data.status === 'success') {
          this.history = response.data.result.result;
        }
      });


    },
    onCreate() {
      this.$vs.loading();
      const data = this.data;

      ApiService.post('b2b-sellers/licence', data).then((response) => {
        this.$vs.loading.close();
        if (response.data.result) {

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Lizenz wurde erfolgreich erstellt.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          this.data = response.data.result;
          return this.$router.push('/licence-manager/' + response.data.result.id)
        }
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Lizenz konnte nicht erstellt werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Lizenz konnte nicht erstellt werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },
    onSave() {
      if (!this.validateForm) return;
      let payload = { ...this.data };

      // Stelle sicher, dass nur der 'value' von commission_agreement_type gespeichert wird
      if (typeof payload.commission_agreement_type === "object" && payload.commission_agreement_type !== null) {
        payload.commission_agreement_type = payload.commission_agreement_type.value;
      }

      ApiService.put('b2b-sellers/licence/' + this.$route.params.id, payload)
          .then((response) => {
            if (response.data.status === 'success') {
              this.data = response.data.result;
              return this.$vs.notify({
                title: 'Erfolgreich',
                text: 'Die Lizenz wurde erfolgreich bearbeitet.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
            }

            this.$vs.notify({
              title: 'Fehlgeschlagen',
              text: 'Die Lizenz konnte nicht bearbeitet werden.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
          .catch((error) => {
            this.$vs.notify({
              title: 'Fehlgeschlagen',
              text: 'Die Lizenz konnte nicht bearbeitet werden.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
    },
    onClickDelete() {
      ApiService.delete('b2b-sellers/licence/' + this.$route.params.id).then((response) => {
        if (response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Lizenz wurde erfolgreich gelöscht.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Lizenz konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Lizenz konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    copyLicenceKey() {

      this.$copyText(this.message)
      this.$vs.notify({
        title: 'Lizenzschlüssel kopiert',
        text: '',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      })

    },
    handleMarketingApprovalChange() {
      if (!this.data.marketing_approval) {
        this.data.marketing_status = null;
      }
    },
    checkRecipient() {
      if (this.data.commission_recipient_client_id !== this.data.partner_client_id) {
        this.recipientMismatch = true;
      } else {
        this.recipientMismatch = false;
      }
      this.updateStoredCommissionPercentage();
    },
    updateStoredCommissionPercentage() {
      const recipient = this.clients.find(
          client => client.id === this.data.commission_recipient_client_id
      );
      this.storedCommissionPercentage = recipient ? recipient.b2b_partner_commission : null;
      this.checkCommissionPercentage();
    },
    checkCommissionPercentage() {
      if (
          this.storedCommissionPercentage !== null &&
          parseInt(this.data.commission_percentage) !== parseInt(this.storedCommissionPercentage)
      ) {
        this.percentageMismatch = true;
      } else {
        this.percentageMismatch = false;
      }
    }

  },
  watch: {
    "data.commission_percentage": function () {
      this.checkCommissionPercentage();
    },
    "data.commission_recipient_client_id": function () {
      this.checkRecipient();
      this.updateStoredCommissionPercentage();
    }
  },
  filters: {
    date(value) {
      return moment(value).format('DD.MM.YYYY');
    },
    dateTime(value) {
      return moment(value).format('DD.MM.YYYY HH:mm') + ' Uhr';
    }
  }
}
</script>

<style lang="scss">
.licence-details {
  .quillWrapper {
    .ql-editor {
      //height: 7rem;
      min-height: 6rem;
    }
  }
}
.text-danger {
  color: red;
  font-size: 0.9em;
}
</style>
